import { stAnalytics } from "@repo/analytics";
import type { curator } from "@repo/client";
import { type Component, Show, createSignal } from "solid-js";
import { PrimaryCTA } from "~/components/CTA/PrimaryCTA";
import { SecondaryCTA } from "~/components/CTA/SecondaryCTA";
import { BlockQuote } from "~/components/SideBar/BlockQuote";
import { formatStoryTileRelevance } from "~/lib/formatting/formatStoryTileRelevance";
import { getScreenType } from "~/lib/ui/getScreenType";
import styles from "./StoryTileDrawerScreen.module.css";

export const StoryTileDrawerScreen: Component<{ tiles: curator.Citation[]; initial: string }> = (props) => {
  const active = () => props.tiles.find((a) => a.citationId === props.initial);
  const [current, setCurrent] = createSignal<curator.Citation | undefined>(active());
  const max = () => props.tiles.length - 1;

  const [position, setPosition] = createSignal<number>(
    props.tiles.findIndex((current) => current.citationId === props.initial),
  );

  const format = (text: string) => {
    const applyPrefix = /^[a-z]/.test(text);
    const applySuffix = !text.endsWith(".") && !text.endsWith("!");
    return `${applyPrefix ? "..." : ""} ${text}${applySuffix ? "..." : ""}`;
  };

  const next = (event: Event) => {
    event.preventDefault();
    if (position() !== max()) {
      setPosition(position() + 1);
      setCurrent(props.tiles[position()]);
    }
    stAnalytics.track("click_tracking", {
      cta: "story_tile_navigate",
      position: "right_drawer",
      screen: getScreenType(),
    });
  };

  const previous = (event: Event) => {
    event.preventDefault();
    if (position() !== 0) {
      setPosition(position() - 1);
      setCurrent(props.tiles[position()]);
    }
    stAnalytics.track("click_tracking", {
      cta: "story_tile_navigate",
      position: "right_drawer",
      screen: getScreenType(),
    });
  };

  return (
    <div class={styles["story-tile-drawer"]}>
      <Show when={props.tiles.length > 1}>
        <div class={styles["story-tile-drawer__top"]}>
          <div class={styles["story-tile-drawer__navigation"]}>
            <SecondaryCTA
              onClick={previous}
              label="Previous"
              accessiblePrefix="View "
              accessibleSuffix="citation."
              data-test-id="next-citation"
              inactive={position() === 0}
            />
            <PrimaryCTA
              onClick={next}
              label="Next"
              accessiblePrefix="View "
              accessibleSuffix="citation."
              data-test-id="next-citation"
              inactive={position() === max()}
            />
          </div>
          <p class={styles["story-tile-drawer__count-container"]}>
            <span class={styles["story-tile-drawer__title-small"]}>StoryTile&trade;</span>
            <span class={styles["story-tile-drawer__count"]}>{position() + 1}</span>
            <span class={styles["story-tile-drawer__count"]}>of</span>
            <span class={styles["story-tile-drawer__count"]}>{max() + 1}</span>
          </p>
        </div>
      </Show>
      <Show when={props.tiles.length === 1}>
        <p class={styles["story-tile-drawer__title"]}>StoryTile&trade;</p>
      </Show>

      {/* <p class={styles["story-tile-drawer__subtitle"]}>Source</p> */}
      {/* <InlineAsset class={styles["story-tile-drawer__asset"]} inline name="My asset" mimeType="pdf" /> */}
      <Show when={current() !== undefined}>
        <p class={styles["story-tile-drawer__subtitle"]}>Extracted from your assets</p>
        <BlockQuote class={styles["story-tile-drawer__extract"]}>
          <p>{format(current()?.text || "")}</p>
        </BlockQuote>
        <p class={styles["story-tile-drawer__text"]}>
          <span>This StoryTile&trade; is considered </span>
          <span class={styles["story-tile-drawer__statistic"]}>
            {formatStoryTileRelevance(current()?.relevance ?? 0)}%
          </span>
          <span> relevant to your question.</span>
        </p>
      </Show>
    </div>
  );
};
