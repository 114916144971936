import { stAnalytics } from "@repo/analytics";
import { TbClipboardCopy } from "solid-icons/tb";
import { type ParentProps, children } from "solid-js";
import { classNames } from "~/lib/classNames";
import { getScreenType } from "~/lib/ui/getScreenType";
import { SecondaryCTA } from "../CTA/SecondaryCTA";
import styles from "./BlockQuote.module.css";

interface Props extends ParentProps {
  class?: string;
}

export const BlockQuote = (props: Props) => {
  const copy = (event: Event) => {
    event.preventDefault();

    let result = "";

    const elements = children(() => props.children);
    if (!elements) return;

    elements.toArray().forEach((element) => {
      if (element instanceof Node && element.textContent) {
        result += element.textContent;
      }
    });
    navigator.clipboard.writeText(result);
    stAnalytics.track("click_tracking", {
      cta: "copy_clipboard",
      position: "right_drawer",
      screen: getScreenType(),
    });
  };

  return (
    // biome-ignore lint/complexity/useLiteralKeys: <explanation>
    <div class={classNames(styles["blockquote"], props.class)}>
      {/* biome-ignore lint/complexity/useLiteralKeys: <explanation> */}
      <blockquote class={classNames(styles["blockquote__text"], "rich-text")}>{props.children}</blockquote>
      {/* biome-ignore lint/complexity/useLiteralKeys: <explanation> */}
      <div class={styles["blockquote__actions"]}>
        <SecondaryCTA
          data-test-id="copy-blockquote-text"
          label="Copy to clipboard"
          accessibleSuffix="the source snippet."
          icon={TbClipboardCopy}
          onClick={copy}
        />
        {/* <SecondaryCTA
          data-test-id="email-blockquote-text"
          label="Share via email"
          accessibleSuffix="the source snippet."
          icon={BiRegularPaperPlane}
          onClick={copy}
        /> */}
      </div>
    </div>
  );
};
