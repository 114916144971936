import { DropdownMenu } from "@kobalte/core";
import { For, type ParentComponent, Show } from "solid-js";
import { Icon, IconName, IconsSize } from "~/components/icons";

export const ThreadTag: ParentComponent<{
  toggleMinimize: () => void;
  isMinimized: boolean;
  menuItems?: { label: string; onClick: () => void; icon: IconName }[];
}> = (props) => {
  return (
    <DropdownMenu.Root placement="top" preventScroll={false}>
      <div class="relative flex items-center gap-4 left-[-2rem] md:left-[-3.5rem] mt-4 mb-4 md:mt-6 md:mb-4">
        <button
          type="button"
          onClick={props.toggleMinimize}
          class="w-5 h-5 min-w-max min-h-max rounded-full border border-violet-300 dark:border-slate-700 bg-white dark:bg-slate-900 dark:ring-slate-900 grid place-content-center"
        >
          <div class="w-2 h-2 bg-violet-700 dark:bg-violet-500 rounded-full" />
        </button>
        <DropdownMenu.Trigger
          as="button"
          class="text-[10px] text-violet-500 dark:text-violet-400 underline underline-offset-4 uppercase tracking-wider min-w-max min-h-max"
        >
          {props.children}
        </DropdownMenu.Trigger>
      </div>
      <DropdownMenu.Portal>
        <DropdownMenu.Content class="min-w-32 text-xs border flex flex-col bg-white dark:text-white dark:bg-slate-800 dark:border-slate-700 animate-fade-out kb-expanded:animate-fade-in rounded">
          <DropdownMenu.Arrow />
          <DropdownMenu.Item
            as="button"
            onClick={props.toggleMinimize}
            class="px-2 py-1 rounded items-center flex gap-2 min-w-max min-h-max"
          >
            <Show
              when={props.isMinimized}
              fallback={
                <>
                  <Icon class="flex items-center w-4 h-4" name={IconName.Minus} />
                  <span>Minimize</span>
                </>
              }
            >
              <Icon class="flex items-center w-4 h-4" name={IconName.Plus} />
              <span>Expand</span>
            </Show>
          </DropdownMenu.Item>

          <For each={props.menuItems}>
            {(item) => (
              <DropdownMenu.Item as="button" onClick={item.onClick} class="px-2 py-1 rounded items-center flex gap-2">
                <Icon class="flex items-center w-4 h-4" name={item.icon} size={IconsSize.Micro} />
                <span>{item.label}</span>
              </DropdownMenu.Item>
            )}
          </For>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};
