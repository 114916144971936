import { stAnalytics } from "@repo/analytics";
import type { curator } from "@repo/client";
import { type Component, For, createMemo } from "solid-js";
import { SideBarSectionAccordion } from "~/components/SideBar/SideBarSectionAccordion";
import { classNames } from "~/lib/classNames";
import { formatStoryTileRelevance } from "~/lib/formatting/formatStoryTileRelevance";
import { getScreenType } from "~/lib/ui/getScreenType";
import { useUIState } from "~/ui/UIState";
import { StoryTileDrawerScreen } from "./StoryTileDrawerScreen";
import styles from "./TextUnitStoryTiles.module.css";

export const TextUnitStoryTiles: Component<{ tiles: curator.Citation[] }> = (props) => {
  const ctx = useUIState();
  const [, setRightContents] = ctx.rightDrawerContents;
  const [rightOpen, setRightOpen] = ctx.rightDrawer;

  const filtered = createMemo(() => props.tiles.filter((t) => t.relevance >= 0));

  return (
    <SideBarSectionAccordion
      label="StoryTiles™"
      initiallyOpen
      depth={0}
      onClick={() => {
        stAnalytics.track("click_tracking", {
          cta: "expand_storytile",
          position: "chat",
          screen: getScreenType(),
        });
      }}
    >
      <div class="w-full max-w-full overflow-x-auto -mx-2 px-2 pb-3 flex gap-3 pt-3 items-stretch">
        <For each={filtered()}>
          {(tile) => (
            <button
              onClick={() => {
                setRightContents(() => () => <StoryTileDrawerScreen tiles={filtered()} initial={tile.citationId} />);
                setRightOpen(!rightOpen());
                stAnalytics.track("click_tracking", {
                  cta: "open_storytile",
                  position: "chat",
                  screen: getScreenType(),
                });
              }}
              type="button"
              class={classNames(styles["story-tile"])}
            >
              <span class={styles["story-tile__label"]}>{tile.text}</span>
              <div class={styles["story-tile__relevance-container"]}>
                <span class={styles["story-tile__relevance"]}>
                  {formatStoryTileRelevance(tile.relevance)}% relevant
                </span>
              </div>
            </button>
          )}
        </For>
      </div>
    </SideBarSectionAccordion>
  );
};
