export const MyersBriggsTypes = [
  "ISTJ",
  "ISTP",
  "ISFJ",
  "ISFP",
  "INTJ",
  "INTP",
  "INFJ",
  "INFP",
  "ESTJ",
  "ESTP",
  "ESFJ",
  "ESFP",
  "ENTJ",
  "ENTP",
  "ENFJ",
  "ENFP",
] as const;
type MyersBriggsType = (typeof MyersBriggsTypes)[number];

export const MyersBriggsShortToLong = (type: MyersBriggsType): string => {
  const [attention, information, decisions, world] = type;
  const res = [];
  if (attention === "I") res.push("Introversion");
  else res.push("Extraversion");
  if (information === "S") res.push("Sensing");
  else res.push("Intuition");
  if (decisions === "T") res.push("Thinking");
  else res.push("Feeling");
  if (world === "J") res.push("Judging");
  else res.push("Perceiving");
  return res.join(", ");
};

export const responsePromptsData = {
  explainLikeImFive: (text: string) => `Based on the content I will share I want you to focus your answers on analyzing just my content + inputs so I can get as personalized a response back as possible.
Respond with: “I’m taking a look at what you’ve shared. Here’s my attempt to break down the concept of [input the provided concept] to a five-year-old.” Then proceed with a response as follows: You are tasked with explaining a concept in a simple, easy-to-understand manner, as if you were explaining it to fifth grader, which is the level that the newspaper USA Today is written in. Your goal is to make complex ideas accessible without losing the essence of the concept. Follow these guidelines to create your explanation:
1. Simplify the concept: Break down complex ideas into their most basic components. Avoid jargon or technical terms unless absolutely necessary.
2. Use concrete examples: Relate the concept to everyday objects, experiences, or situations that a fifth grader might be familiar with. Fifth graders are around 10-12 years old.
3. Use analogies: Compare the concept to something a fifth grader already understands. Keep in mind that fifth graders have rich internal lives, emotionally and intellectually. So don’t see your reader short!
4. Keep it brief: Limit your explanation to a few short paragraphs. Humans have shorter attention spans these days thanks to social media: so concise explanations are more effective.
5. Use language that matches your audience: Adjust your vocabulary and sentence structure to match the target age group.
6. Engage the imagination: Use descriptive language that helps a fifth grader visualize the concept.
7. Avoid oversimplification: While simplifying, ensure that the core idea remains accurate and isn't misrepresented. Please provide your explanation within \u003cexplanation\u003e tags.
Begin with a brief introduction that captures the reader’s attention, then proceed with your simplified explanation of the concept. Use the provided content as the concept to explore unless you get different instructions from the user.

Content to explain: "${text}"`,

  whyDoesThisMatter: (text: string) => `I need more context about why this is important; why it matters. Please help me understand why the exerpt below is relevant, as follows:

- What related material does this reference that would give me mroe context about this?
- What other hidden insights or connections can you help me make to this content that will help me understand it?

Begin your answer with "Here's more context about why this matters:" and then share your answer.

Here's the excerpt in question that I need the context around:

${text}`,

  getMoreDetail: (text: string) =>
    `Provide more detail on this excerpt: "${text}" 

Here's how I'd like to get this additional deal:
- Go as in-depth as you can. I want as many specifics as you can find that relate to the excerpt above.

- Start your response with "Sure, here's more detail as you requested:"

- Then, provide an executive summary of the additional detail you found. Title it "Summary of additional details:"

- After the executive summary, create a bullet point list of the additional details you found. For each bullet point, bold the title of the additional detail, followed by a description of that detail. Title this lis "Specific points:"

- After the bullet point list, write out as much detail as you can offer. This section can be as long as necessary -- more detail is beter. Title this section "Deeper dive on additional details:"
`,

  findMoreLikeThis: (text: string) =>
    `I want to find all the content that is similar to what's below. When you share the similar content with me, create two sections. Title the first section "Similar content from knowledge powering this thread" and only provide content that is contained in the body of content I've shared.  Strive to provide at least five examples, but if you can find 10, 20 or more, please do. All examples should have similarity scores of 50% or higher.

Title the second section "Similar content from the world's knowledge" and look for similar content that is not from the content I've shared, but that you know is similar from your knowledge training set.

When you share the results of both sections do so in bullet points. For each bullet point, do the following: List a short summary of the similar content in bold, followed by a detailed description. Put a sub bullet point with a "Similarity score" and rate from a scale of 0% (not at all similar) to 100% (identical) how similar this content is to the original text below. Next, put a second sub bullet point titled "How this content is similar:" and describe why you chose to list this content as similar to the original text below. 

Once you are finished, ask me if I'd like you to find more similar content, and if so, repeat the process above to find more results.

${text}`,

  rewriteForBrevity: (text: string) => `Rewrite the following text with a focus on brevity by bolding the main concept in your response and returning no more than a one-paragraph executive summary. 
Begin your response with "Here's my last answer re-written for brevity:"

${text}`,
  rewriteForExecutive: (text: string) => `Rewrite the following text in a more professional tone for an executive audience that wants to quickly grasp the content, understand its impact, and be presented with a set of actionable recommendations around next steps:

${text}
`,

  rewriteForDepth: (text: string) => `Rewrite the following text with a focus on depth. I want as many specifics as you can provide. 
Begin your response with "Here's my last answer re-written with more depth:"

${text}`,

  rewriteForLessTechnical: (text: string) => `Rewrite the following text for a non-technical audience. Use vivid imagery, analogies and metaphors where applicable to help this audience understand the material:
Begin your response with "Here's my last answer re-written for a less technical audience:"

${text}`,

  rewriteForMoreTechnical: (text: string) => `Rewrite the following text for a more technical audience. Provide as much technical depth and detail as possible: 
Begin your response with "Here's my last answer re-written for a more technical audience:"

${text}`,

  rewriteForMyersBriggs: (
    text: string,
    type: MyersBriggsType,
  ) => `I need to rewrite this content for an ${type} Myers Briggs personality type, which is "${MyersBriggsShortToLong(
    type,
  )}".

Begin with a section titled "These are the primary traits of ${type}s" and share a paragraph about what makes them tick and what kind of approach they best respond to.

Then perform an analysis of the original content and share the changes you'll make to it so it's more palatable for this personality type. Use bullet point format, along with why. 

Then create a section titled "Rewrite to optimize for an ${type}" and share your re-written version of the content for that personality type.

${text}
  `,

  analyzeFeelingsEmotions: (text: string) => `Please analyze the following text to give me a detailed understanding of all feelings and emotions that were expressed in the content.

First respond with a title, "Here's a detailed analysis of feelings and emotions expressed in the last response:"

Then format your response in a table format, as follows:
- Column 1: Titled "Feeling or Emotion" -- this is a word or phrase that encapsulates the feeling or emotion identified
- Column 2: Tttled "Expressed by" -- this should be the person who expressed the feeling or emotion. Ideally you're able to identify a speaker name. If not, return "unidentified"
- Column 3: Titled "Sentiment" -- Share whether it was very positive, positive, neutral, negative, very negative, or unclear 
- Column 4: Titled "Analysis" -- share a one paragraph analysis of this feeling or emotion. Why did it happen? What caused it? What was the impact of it? 
- Column 5: Titled "Creative Strategies" -- share some wild ideas and creative strategies on how to ideally respond to and manage the feeling or emotion expressed.

Below that, share detail on what the overall tone of the content was. Your output might look something like this:

"## Overall Tone of Content

The primary emotion expressed was [name of emotion], which was observed by [list of participant names]. There was also a high level of [emotion name] expressed by [participants] for [these reasons]."

Lastly, end with "How does this analysis resonate? I'd like to get your feedback, which I can further incorporate into my answer."

If the person provides feedback on the analysis, try responding with an updated version that incorporates the user's feedback and ask if that seems more accurate.

Text to analyze:

${text}
`,

  analyzeMetUnmetNeeds: (text: string) => `Please analyze the following text to give me a detailed understanding of all met needs and unmet needs that were expressed in the content.

First respond with a title, "Here's a detailed analysis of met needs and unmet needs expressed in the last response:"

Then format your response in a table format, as follows:
- Column 1: Titled "Need" -- this is a word or phrase that encapsulates the need expressed
- Column 2: Tttled "Expressed by" -- this should be the person who expressed the need. Ideally you're able to identify a speaker name. If not, return "unidentified"
- Column 3: Titled "Met or Unmet?" -- Share whether it was a "Met Need," which means the person had positive emotions associated with that need, or an "Unmet Need", which means the person had negative emotions associated with it
- Column 4: Titled "Associated Emotions" -- Share a list of emotions that were attached to that person's need
- Column 5: Titled "Analysis" -- share a one paragraph analysis of this need. Why did it happen? What caused it? What was the impact of it? 
- Column 6: Titled "Creative Strategies" -- share some wild ideas and creative strategies on how to ideally respond to and manage the need expressed.

Below that, share detail on what the overall analsyis was around the needs, both met and unmet. Your output might look something like this:

"## Overall Analysis of Needs

The content primarily showcases a set of [met or unmet] needs by [participant list] for [these reasons]." 

Lastly, end with "How does this analysis resonate? I'd like to get your feedback, which I can further incorporate into my answer."

If the person provides feedback on the analysis, try responding with an updated version that incorporates the user's feedback and ask if that seems more accurate.

Text to analyze:

${text}`,
  analyzeGoneBetter: (text: string) => `Please analyze the following text to give me a detailed understanding of how this could have gone better.

First respond with a title, "Here's a detailed analysis of what did not go as well as it could have:"

Then format your response in a table format, as follows:
- Column 1: Titled "Issue" -- this is a word or phrase about something that did not go as well as it could have
- Column 2: Tttled "Expressed by" -- this should be the person who expressed the issue. Ideally you're able to identify a speaker name. If not, return "unidentified"
- Column 3: Titled "Analysis" -- share a one paragraph analysis of what went wrong. Why did it happen? What caused it? What was the impact of it? 
- Column 4: Titled "Creative Strategies" -- share some wild ideas and creative strategies on how to have made it go better.

Below that, share detail on what the overall analsyis was around what could have gone better. Your output might look something like this:

"## Overall Analysis of What Could have Gone Better:

Overall, things went [very well / well / OK / poorly / very poorly] for [these reasons]. These creative strategies could be employed to improve the outcome: [Summarize the creative strategies listed in the table]"

Lastly, end with "How does this analysis resonate? I'd like to get your feedback, which I can further incorporate into my answer."

If the person provides feedback on the analysis, try responding with an updated version that incorporates the user's feedback and ask if that seems more accurate.

Text to analyze:

${text}`,
  analyzePersonInner: (text: string) => `Please analyze the following text to give me a detailed understanding of what's really happening inside each of the participants listed.

I need to "read between the lines" and understand the tone, intent and unsaid parts of the original response so I can better undertand each participants feelings, emotions, needs and motivations.

Pretend you are a therapist diagnosing each participant by putting together the puzzle pieces of what's really going on inside them, even if it's not what they are actually saying. You're looking for patterns and clues to come to reasonable conslusions.

First respond with a title, "Here's a detailed analysis of what might really be happening inside participants:"

Then format your response in a table format, as follows:
- Column 1: Titled "Reading Between the Lines" -- this is a word or phrase that describes an observation you're making based on your therapist work about something that's happening inside someone
- Column 2: Tttled "Expressed by" -- this should be the person who expressed the observation. Ideally you're able to identify a speaker name. If not, return "unidentified"
- Column 3: Titled "Analysis" -- share a one paragraph analysis of what's happening inside the participant, based on the observation you made. Why did it happen? What caused it? What was the impact of it? If it's not something they specifically stated, what are the underlying drivers that are making it happen?
- Column 4: Titled "Creative strategies" -- share some wild ideas and creative strategies on how to respond to the observation in a way that will produce positive results.

Below that, share detail on what an overall analsyis around what's really happening indide the participants. Your output might look something like this:

"## Overall Analysis of What Really Happening Inside Participants:

Overall, I identified [list of observations] that may be happening inside [list of participants]. These creative strategies could be employed to respond in an optimal way: [Summarize the creative strategies listed in the table]"

Lastly, end with "How does this analysis resonate? I'd like to get your feedback, which I can further incorporate into my answer."

If the person provides feedback on the analysis, try responding with an updated version that incorporates the user's feedback and ask if that seems more accurate.

Text to analyze:

${text}`,

  analyzeUnderSurface: (text: string) => `Please analyze the following text to give me a detailed understanding of what's really happening under the surface.

I need to "read between the lines" and understand the tone, intent and unsaid parts of the original response so I can better undertand the undertones of the content and the motivations behind it.

Pretend you are a detective analyzing the content by putting together the puzzle pieces of what the hidden messages, patterns and undercurrents are, even if it's not what they are actually saying. You're looking for patterns and clues to come to reasonable conslusions.

First respond with a title, "Here's a detailed analysis of what might really be happening under the surface:"

Then format your response in a table format, as follows:
- Column 1: Titled "Reading Between the Lines" -- this is a word or phrase that describes an observation you're making based on your detecive work about something that's happening under the surface
- Column 2: Tttled "Expressed by" -- this should be the person who expressed the observation. Ideally you're able to identify a speaker name. If not, return "unidentified"
- Column 3: Titled "Analysis" -- share a one paragraph analysis of what's happening under the surface, based on the observation you made. Why did it happen? What caused it? What was the impact of it? If it's not something they specifically stated, what are the underlying drivers that are making it happen?
- Column 4: Titled "Creative strategies" -- share some wild ideas and creative strategies on how to respond to the observation in a way that will produce positive results.

Below that, share detail on what an overall analsyis around what's really happening under the surface. Your output might look something like this:

"## Overall Analysis of What Really Happening Under the Surface:

Overall, I identified [list of observations] that may be happening under the surface, as expressed by [list of participants]. These creative strategies could be employed to respond in an optimal way: [Summarize the creative strategies listed in the table]"

Lastly, end with "How does this analysis resonate? I'd like to get your feedback, which I can further incorporate into my answer."

If the person provides feedback on the analysis, try responding with an updated version that incorporates the user's feedback and ask if that seems more accurate.

Text to analyze:

${text}`,

  shareViaEmailMailtoLink: ({
    text,
    threadName,
    threadUrl,
    userDisplayName,
  }: {
    text: string;
    threadName: string;
    userDisplayName: string;
    threadUrl: string;
  }) => {
    const subject = `Storytell snippet from ${threadName}`;
    const body = `${userDisplayName} is sharing this snippet with you. You can read then entire thread at ${threadUrl}

=================
${text}
=================

Learn more at https://Storytell.ai`;

    return `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
  },

  translate: (language: string, text: string) => `Translate this into ${language}: ${text}`,
};
