export const languagesByPopularity = [
  {
    code: "zh",
    name: "Chinese",
    speakers: 1026000000,
  },
  {
    code: "en",
    name: "English",
    speakers: 765000000,
  },
  {
    code: "es",
    name: "Spanish",
    speakers: 466000000,
  },
  {
    code: "ar",
    name: "Arabic",
    speakers: 425000000,
  },
  {
    code: "ru",
    name: "Russian",
    speakers: 272000000,
  },
  {
    code: "bn",
    name: "Bengali",
    speakers: 250000000,
  },
  {
    code: "pt",
    name: "Portuguese",
    speakers: 217000000,
  },
  {
    code: "sw",
    name: "Swahili",
    speakers: 140000000,
  },
  {
    code: "ja",
    name: "Japanese",
    speakers: 123000000,
  },
  {
    code: "de",
    name: "German",
    speakers: 111800000,
  },
  {
    code: "jw",
    name: "Javanese",
    speakers: 84300000,
  },
  {
    code: "te",
    name: "Telugu",
    speakers: 79000000,
  },
  {
    code: "ta",
    name: "Tamil",
    speakers: 76800000,
  },
  {
    code: "sd",
    name: "Sindhi",
    speakers: 75000000,
  },
  {
    code: "mr",
    name: "Marathi",
    speakers: 71800000,
  },
  {
    code: "fr",
    name: "French",
    speakers: 68500000,
  },
  {
    code: "vi",
    name: "Vietnamese",
    speakers: 67800000,
  },
  {
    code: "ko",
    name: "Korean",
    speakers: 66400000,
  },
  {
    code: "ur",
    name: "Urdu",
    speakers: 63400000,
  },
  {
    code: "it",
    name: "Italian",
    speakers: 61100000,
  },
  {
    code: "ps",
    name: "Pashto",
    speakers: 60000000,
  },
  {
    code: "th",
    name: "Thai",
    speakers: 60000000,
  },
  {
    code: "yue",
    name: "Yueh",
    speakers: 60000000,
  },
  {
    code: "ms",
    name: "Malay",
    speakers: 59400000,
  },
  {
    code: "fa",
    name: "Persian",
    speakers: 56600000,
  },
  {
    code: "tr",
    name: "Turkish",
    speakers: 50700000,
  },
  {
    code: "kn",
    name: "Kannada",
    speakers: 46700000,
  },
  {
    code: "gu",
    name: "Gujarati",
    speakers: 46600000,
  },
  {
    code: "my",
    name: "Burmese",
    speakers: 42900000,
  },
  {
    code: "ha",
    name: "Hausa",
    speakers: 40000000,
  },
  {
    code: "pl",
    name: "Polish",
    speakers: 39000000,
  },
  {
    code: "ml",
    name: "Malayalam",
    speakers: 38000000,
  },
  {
    code: "su",
    name: "Sundanese",
    speakers: 38000000,
  },
  {
    code: "uk",
    name: "Ukrainian",
    speakers: 36000000,
  },
  {
    code: "ku",
    name: "Kurdish",
    speakers: 30000000,
  },
  {
    code: "pa",
    name: "Punjabi",
    speakers: 29500000,
  },
  {
    code: "az",
    name: "Azerbaijani",
    speakers: 28000000,
  },
  {
    code: "uz",
    name: "Uzbek",
    speakers: 27000000,
  },
  {
    code: "zu",
    name: "Zulu",
    speakers: 26000000,
  },
  {
    code: "am",
    name: "Amharic",
    speakers: 25800000,
  },
  {
    code: "tl",
    name: "Filipino",
    speakers: 25000000,
  },
  {
    code: "id",
    name: "Indonesian",
    speakers: 23000000,
  },
  {
    code: "ro",
    name: "Romanian",
    speakers: 23000000,
  },
  {
    code: "nl",
    name: "Dutch",
    speakers: 22000000,
  },
  {
    code: "ceb",
    name: "Cebuano",
    speakers: 21000000,
  },
  {
    code: "yo",
    name: "Yoruba",
    speakers: 21000000,
  },
  {
    code: "lo",
    name: "Lao",
    speakers: 20000000,
  },
  {
    code: "ig",
    name: "Igbo",
    speakers: 18000000,
  },
  {
    code: "mg",
    name: "Malagasy",
    speakers: 18000000,
  },
  {
    code: "hr",
    name: "Croatian",
    speakers: 16000000,
  },
  {
    code: "km",
    name: "Khmer",
    speakers: 16000000,
  },
  {
    code: "sr",
    name: "Serbian",
    speakers: 16000000,
  },
  {
    code: "sr-LA",
    name: "Serbian",
    speakers: 16000000,
  },
  {
    code: "si",
    name: "Sinhalese",
    speakers: 16000000,
  },
  {
    code: "af",
    name: "Afrikaans",
    speakers: 15200000,
  },
  {
    code: "ne",
    name: "Nepali",
    speakers: 14000000,
  },
  {
    code: "so",
    name: "Somali",
    speakers: 14000000,
  },
  {
    code: "el",
    name: "Greek",
    speakers: 13000000,
  },
  {
    code: "hi",
    name: "Hindi",
    speakers: 12500000,
  },
  {
    code: "hu",
    name: "Hungarian",
    speakers: 12500000,
  },
  {
    code: "ny",
    name: "Chichewa",
    speakers: 12000000,
  },
  {
    code: "ca",
    name: "Catalan",
    speakers: 11500000,
  },
  {
    code: "kk",
    name: "qazaq tili",
    speakers: 11000000,
  },
  {
    code: "cs",
    name: "Czech",
    speakers: 9500000,
  },
  {
    code: "bg",
    name: "Bulgarian",
    speakers: 9100000,
  },
  {
    code: "be",
    name: "Belarusian",
    speakers: 8600000,
  },
  {
    code: "tg",
    name: "Tajik",
    speakers: 8400000,
  },
  {
    code: "sv",
    name: "Swedish",
    speakers: 8300000,
  },
  {
    code: "xh",
    name: "Xhosa",
    speakers: 8200000,
  },
  {
    code: "ht",
    name: "Haitian Creole",
    speakers: 7700000,
  },
  {
    code: "sq",
    name: "Albanian",
    speakers: 7500000,
  },
  {
    code: "kok",
    name: "Konkani",
    speakers: 7400000,
  },
  {
    code: "ckb",
    name: "Central Kurdish",
    speakers: 7250000,
  },
  {
    code: "hy",
    name: "Armenian",
    speakers: 6400000,
  },
  {
    code: "mn",
    name: "Mongolian",
    speakers: 5700000,
  },
  {
    code: "da",
    name: "Danish",
    speakers: 5600000,
  },
  {
    code: "st",
    name: "Sotho",
    speakers: 5600000,
  },
  {
    code: "sdh",
    name: "Southern Kurdish",
    speakers: 5390000,
  },
  {
    code: "he",
    name: "Hebrew",
    speakers: 5300000,
  },
  {
    code: "fj",
    name: "Fijian",
    speakers: 5000000,
  },
  {
    code: "fi",
    name: "Finnish",
    speakers: 5000000,
  },
  {
    code: "no",
    name: "Norwegian",
    speakers: 5000000,
  },
  {
    code: "sk",
    name: "Slovak",
    speakers: 5000000,
  },
  {
    code: "ka",
    name: "Georgian",
    speakers: 4300000,
  },
  {
    code: "ky",
    name: "Кyrgyz",
    speakers: 4300000,
  },
  {
    code: "hmn",
    name: "Hmong",
    speakers: 4000000,
  },
  {
    code: "bs",
    name: "Bosnian",
    speakers: 3500000,
  },
  {
    code: "lt",
    name: "Lithuanian",
    speakers: 3000000,
  },
  {
    code: "mk",
    name: "Macedonian",
    speakers: 2500000,
  },
  {
    code: "sl",
    name: "Slovenian",
    speakers: 2500000,
  },
  {
    code: "sn",
    name: "Slovenian",
    speakers: 2500000,
  },
  {
    code: "gl",
    name: "Galician",
    speakers: 2400000,
  },
  {
    code: "eo",
    name: "Esperanto",
    speakers: 2000000,
  },
  {
    code: "lv",
    name: "Latvian",
    speakers: 1700000,
  },
  {
    code: "yi",
    name: "Yiddish",
    speakers: 1500000,
  },
  {
    code: "sjn",
    name: "Elvish",
    speakers: 1200000,
  },
  {
    code: "et",
    name: "Estonian",
    speakers: 1200000,
  },
  {
    code: "bo",
    name: "Tibetan",
    speakers: 1200000,
  },
  {
    code: "ga",
    name: "Irish",
    speakers: 1000000,
  },
  {
    code: "yua",
    name: "Yucatec Maya",
    speakers: 790000,
  },
  {
    code: "cy",
    name: "Welsh",
    speakers: 740000,
  },
  {
    code: "eu",
    name: "Basque",
    speakers: 720000,
  },
  {
    code: "mt",
    name: "Maltese",
    speakers: 520000,
  },
  {
    code: "sm",
    name: "Samoan",
    speakers: 510000,
  },
  {
    code: "fy",
    name: "Frisian",
    speakers: 470000,
  },
  {
    code: "lb",
    name: "Luxembourgish",
    speakers: 390000,
  },
  {
    code: "is",
    name: "Icelandic",
    speakers: 330000,
  },
  {
    code: "pap",
    name: "Papiamento",
    speakers: 260000,
  },
  {
    code: "otq",
    name: "Hñohño",
    speakers: 260000,
  },
  {
    code: "co",
    name: "Corsican",
    speakers: 200000,
  },
  {
    code: "to",
    name: "Tongan",
    speakers: 96000,
  },
  {
    code: "gd",
    name: "Scottish Gaelic",
    speakers: 87000,
  },
  {
    code: "ty",
    name: "Tahitian",
    speakers: 68000,
  },
  {
    code: "mi",
    name: "Māori",
    speakers: 60000,
  },
  {
    code: "iu",
    name: "Inuktitut",
    speakers: 34000,
  },
  {
    code: "haw",
    name: "Hawaiian",
    speakers: 2000,
  },
  {
    code: "iku",
    name: "Inuinnaqtun",
    speakers: 410,
  },
  {
    code: "fo",
    name: "Faroese",
    speakers: null,
  },
];
