import type { IconTypes } from "solid-icons";
import { classNames } from "~/lib/classNames";
import { StIcon } from "../icons";
import styles from "./IconLabel.module.css";

interface Props {
  icon: IconTypes;
  label: string;
  class?: string;
  modifier?: "italic";
}

export const IconLabel = (props: Props) => {
  return (
    <p
      class={classNames(
        styles["icon-label"],
        props.modifier === "italic" ? styles["icon-label--italic"] : "",
        props.class,
      )}
    >
      <StIcon icon={props.icon} class={styles["icon-label__icon"]} size="1.125rem" />
      <span class={styles["icon-label__label"]}>{props.label}</span>
    </p>
  );
};
