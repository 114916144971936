import { type AssetLifecycleState, AssetLifecycleStates, type curator } from "@repo/client";
import { TbMinus, TbPlus } from "solid-icons/tb";
import { type Component, For, Match, Show, Switch, createEffect, createSignal, onCleanup } from "solid-js";
import { CircularStatus } from "~/components/CircularStatus";
import { StIcon } from "~/components/icons";

export const KnowledgeChangeUnitV1: Component<{
  message: curator.MessageKnowledgeV1;
}> = (props) => {
  return (
    <section class="mb-12">
      <h4 class="uppercase text-2xs leading-normal tracking-wider text-indigo-500 dark:text-indigo-400 font-semibold mb-2">
        Knowledge changed
      </h4>
      <ul class="flex flex-col gap-2">
        <For each={props.message.knowledgeFull.assetContext.added}>
          {(item) => (
            <KnowledgeChangeUnitItem
              plus
              displayName={item.displayName}
              originalFilename={item.originalFilename}
              lifecycleState={item.lifecycleState}
              failureReason={item.processingFailureReason?.String ?? ""}
              progress={props.message.progress?.[item.id]}
            />
          )}
        </For>
      </ul>
      <ul class="flex flex-col gap-2">
        <For each={props.message.knowledgeFull.assetContext.removed}>
          {(item) => (
            <KnowledgeChangeUnitItem
              plus={false}
              displayName={item.displayName}
              originalFilename={item.originalFilename}
              lifecycleState={item.lifecycleState}
              failureReason={item.processingFailureReason?.String ?? ""}
              progress={props.message.progress?.[item.id]}
            />
          )}
        </For>
      </ul>
    </section>
  );
};

const formatPct = (pct: number): string => {
  if (pct < 0) return "0%";
  if (pct >= 1) return "100%";
  return `${Math.round(pct * 100)}%`;
};

export const KnowledgeChangeUnitItem: Component<{
  displayName: string;
  originalFilename: string;
  plus: boolean;
  lifecycleState: string;
  failureReason: string;
  progress?: curator.ProcessingStatus;
}> = (props) => {
  const [lastState, setLastState] = createSignal(props.lifecycleState);
  const [isReady, setIsReady] = createSignal(props.lifecycleState === AssetLifecycleStates.Ready);

  const formatMessage = (message: string) => {
    return message ? message.replaceAll("{}", "") : "";
  };

  // Hide state if it is ready after a moment for the user to see it
  createEffect(() => {
    if (props.lifecycleState !== lastState()) {
      setLastState(props.lifecycleState);
      if (props.lifecycleState === AssetLifecycleStates.Ready) {
        setTimeout(() => setIsReady(true), 3_000);
      }
    }
  });

  const hasFailed = (s: AssetLifecycleState) => {
    switch (s) {
      case AssetLifecycleStates.EmbeddingFailed:
      case AssetLifecycleStates.SummarizationFailed:
      case AssetLifecycleStates.Failed:
      case AssetLifecycleStates.ExtractionFailed:
      case AssetLifecycleStates.TranscriptionFailed:
        return true;
      default:
        return false;
    }
  };

  return (
    <li class="flex items-start gap-2 text-slate-700 dark:text-slate-300 font-medium">
      <Show
        fallback={
          <span class="mt-1">
            <CircularStatus hasFailed={hasFailed(props.lifecycleState)} progress={props?.progress?.percentage ?? 0} />
          </span>
        }
        when={isReady()}
      >
        <StIcon
          classList={{
            "text-red-600 dark:text-red-300": !props.plus,
            "text-green-600 dark:text-green-300": props.plus,
          }}
          icon={props.plus ? TbPlus : TbMinus}
          class="mt-[4px] stroke-[3px] leading-normal text-base"
        />
      </Show>
      <div>
        <span class="block">{props.displayName}</span>
        <Show when={props.displayName !== props.originalFilename}>
          <span class="block text-sm leading-normal text-gray-400 dark:text-gray-600">{props.originalFilename}</span>
        </Show>
        <Show when={!isReady()}>
          <Switch fallback={<LifecycleLabel state={props.lifecycleState} />}>
            <Match when={hasFailed(props.lifecycleState)}>
              <LifecycleLabel state={props.lifecycleState} />
              <span class="block text-sm leading-normal text-red-500 dark:text-red-400">
                {formatMessage(props.progress?.message ?? "")}
              </span>
            </Match>
            <Match when={props.progress}>
              <LifecycleLabel state={props.lifecycleState} />
              <span class="block text-sm leading-normal">
                {formatPct(props.progress?.percentage ?? 0)}
                {props.progress?.message ? ` - ${formatMessage(props.progress.message)}` : ""}
              </span>
            </Match>
          </Switch>
          {/*<Show*/}
          {/*  when={hasFailed(props.lifecycleState) && props.failureReason !== ""}*/}
          {/*>*/}
          {/*  <span class="block text-sm text-red-500 dark:text-red-400">*/}
          {/*    {props.failureReason}*/}
          {/*  </span>*/}
          {/*</Show>*/}
        </Show>
      </div>
    </li>
  );
};

const LifecycleLabel: Component<{ state: string }> = (props) => {
  const [stepMessage, setStepMessage] = createSignal(0);
  let lastState = "";
  const capitalizeAndConvert = (s: string) =>
    !s
      ? ""
      : s
          .split("_")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");

  let to: NodeJS.Timeout | undefined = undefined;

  createEffect(() => {
    if (props.state !== lastState) {
      clearTimeout(to);
      lastState = props.state;
      setStepMessage(0);
      // to = setTimeout(() => setStepMessage(), 5_000);
      to = setInterval(() => setStepMessage(stepMessage() + 1), 5_000);
    }
  });

  onCleanup(() => {
    if (to) clearTimeout(to);
  });

  return (
    <span class="block text-sm text-indigo-500 dark:text-indigo-400 ">
      {capitalizeAndConvert(props.state)}
      <Show when={stepMessage() > 0}>
        <Switch>
          <Match when={props.state === AssetLifecycleStates.Transcribing}>
            <span class="px-2">{getTranscribingStepMessage(stepMessage())}</span>
          </Match>
          <Match when={props.state === AssetLifecycleStates.Extracting}>
            <span class="px-2">{getExtractingStepMessage(stepMessage())}</span>
          </Match>
        </Switch>
      </Show>
    </span>
  );
};

const getTranscribingStepMessage = (step: number) => {
  const a = "Please wait while we transcribe.";
  const b = "This process may take a few minutes.";
  switch (step) {
    case 1:
      return "This step may take a minute or two.";
    case 2:
      return a;
    case 3:
      return `${a}.`;
    case 4:
      return `${a}..`;
    case 5:
      return b;
    case 6:
      return `${b}.`;
    case 7:
      return `${b}..`;
    default:
      getTranscribingStepMessage(Math.ceil(Math.random() * 7));
  }
};

const getExtractingStepMessage = (step: number) => {
  const a = "Please wait while we extract content.";
  const b = "This process may take a minute or two.";
  switch (step) {
    case 1:
      return "This step may take a minute.";
    case 2:
      return a;
    case 3:
      return `${a}.`;
    case 4:
      return `${a}..`;
    case 5:
      return b;
    case 6:
      return `${b}.`;
    case 7:
      return `${b}..`;
    default:
      getExtractingStepMessage(Math.ceil(Math.random() * 7));
  }
};
