import {
  type ThreadMessage,
  ThreadMessageKinds,
  isMessageKnowledgeV1,
  isMessagePrompt,
  isMessageTextV1,
  isThreadInsert,
  type q,
  type curator,
} from "@repo/client";
import type { ThreadGroup, ThreadUnit } from "../screens/ThreadScreen";

const sortStrings = (a: string, b: string) => a.localeCompare(b);
const sortAssets = (a: q.ControlplaneSsAsset, b: q.ControlplaneSsAsset) =>
  sortStrings(a.displayName || a.originalFilename, b.displayName || b.originalFilename);

const mergeKnowledgeChange = (
  m1: curator.MessageKnowledgeV1,
  m2: curator.MessageKnowledgeV1,
): curator.MessageKnowledgeV1 => {
  const mapAdded: Record<string, q.ControlplaneSsAsset> = {};
  const mapRemoved: Record<string, q.ControlplaneSsAsset> = {};

  m1.knowledgeFull.assetContext.added.forEach((a) => {
    mapAdded[a.id] = a;
  });
  m2.knowledgeFull.assetContext.added.forEach((a) => {
    mapAdded[a.id] = a;
  });
  m1.knowledgeFull.assetContext.removed.forEach((a) => {
    mapRemoved[a.id] = a;
  });
  m2.knowledgeFull.assetContext.removed.forEach((a) => {
    mapRemoved[a.id] = a;
  });

  Object.keys(mapAdded).forEach((id) => {
    if (mapRemoved[id]) {
      delete mapAdded[id];
      delete mapRemoved[id];
    }
  });

  const sortedAdded = Object.values(mapAdded).sort(sortAssets);
  const sortedRemoved = Object.values(mapRemoved).sort(sortAssets);
  return {
    ...m2,
    knowledge: {
      ...m2.knowledge,
      assetContext: {
        added: sortedAdded.map((a) => a.id),
        removed: sortedRemoved.map((a) => a.id),
      },
    },
    knowledgeFull: {
      ...m2.knowledgeFull,
      assetContext: {
        added: sortedAdded,
        removed: sortedRemoved,
      },
    },
  };
};

export const mapThreadMessagesToUIData = (deps: {
  messages: ThreadMessage[];
  threadId: string | null;
  updateThreadLabel: (threadId: string, label: string) => void;
}) => {
  const messages = deps.messages;
  const grouped: (ThreadGroup | ThreadUnit)[] = [];
  let last: ThreadGroup | ThreadUnit = { type: "group", content: [] };

  for (const m of messages) {
    if (isMessagePrompt(m)) {
      last = { type: "group", content: [m] };
      grouped.push(last);
      continue;
    }

    if (isMessageKnowledgeV1(m)) {
      if (last.type === "unit" && isMessageKnowledgeV1(last.content)) {
        last.content = mergeKnowledgeChange(last.content, m);
      } else {
        last = { type: "unit", content: m };
        grouped.push(last);
      }
      continue;
    }

    if (isThreadInsert(m)) {
      last = { type: "unit", content: m };
      grouped.push(last);
    }

    if (isMessageTextV1(m)) {
      if (m.isDone && m.label) {
        if (deps.threadId === m.threadId) deps.updateThreadLabel(deps.threadId, m.label);
      }
    }
    if (m.kind === ThreadMessageKinds.MessageKindRenameThreadV1) continue;
    // if (
    //   isContentSuggestedActions(m.content) &&
    //   index !== filtered.length - 1
    // ) {
    //   return;
    // }

    if (last.type === "unit") {
      last = { type: "group", content: [] };
    }
    last.content.push(m);
  }

  return grouped;
};
