import { type ParentComponent, createSignal, Show } from "solid-js";
import { ThreadTag } from "./ThreadTag";
import type { IconName } from "~/components/icons";

export const ThreadTimelineUnit: ParentComponent<{
  tagLabel: string;
  menuItems?: { label: string; onClick: () => void; icon: IconName }[];
}> = (props) => {
  const [mini, setMini] = createSignal(false);

  return (
    <>
      <ThreadTag isMinimized={mini()} toggleMinimize={() => setMini(!mini())} menuItems={props.menuItems}>
        {props.tagLabel}
      </ThreadTag>
      <Show when={!mini()}>{props.children}</Show>
    </>
  );
};
