import { type Component, createSignal, Show } from "solid-js";
import { StButton } from "~/components/buttons";
import { TbClipboardCheck, TbClipboardCopy } from "solid-icons/tb";

export const CopyButton: Component<{
  content: string;
  label: string;
  onClick?: () => void | Promise<void>;
  class?: string;
  simple?: boolean;
  showLabel?: boolean;
}> = (props) => {
  const [copied, setCopied] = createSignal(false);
  return (
    <StButton
      class={props.class}
      onClick={async () => {
        await navigator.clipboard.writeText(props.content);
        await props.onClick?.();
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 3_000);
      }}
      simple={props.simple ?? true}
      label={props.label}
      icon={copied() ? TbClipboardCheck : TbClipboardCopy}
    >
      <Show when={props.showLabel}>{props.label}</Show>
    </StButton>
  );
};
